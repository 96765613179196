import React from 'react'

export const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-start'>
        <h5>Finn oss</h5>
        <a href='https://goo.gl/maps/U2xEruiWqPxew3wN9'>Stortorvet 10</a>
        <p>0155 Oslo</p>
        <p>Inngang fra Møllergata</p>
      </div>
      <div className='footer-center'>
        <h5>Finn oss på sosiale medier</h5>
        <a href='https://www.facebook.com/oslofoto.as/'>Facebook</a>
        <a href='https://www.instagram.com/oslofoto.as/?hl=nb'>Instagram</a>
      </div>
      <div className='footer-end'>
        <h5>Mine sider</h5>
        <a href='https://www.oslofoto.as/minesider/mineordre'>Mine ordre</a>
        <a href='https://www.oslofoto.as/minesider'>Min profil</a>
      </div>
      <div className='footer-start'>
        <h5>Kontakt oss</h5>
        <p>Tlf: 22 33 55 22</p>

        <p>
          E-post:
          <a href='mailto:ordre@oslofoto.as' style={{ display: 'inline' }}>
            ordre@oslofoto.as
          </a>{' '}
        </p>
      </div>
      <div className='footer-center'>
        <h5>Om Oslo Foto</h5>
        <a href='https://www.oslofoto.as/omoss'>Les mer om Oslo Foto</a>
      </div>
      <div className='footer-end'>
        <h5>Vilkår</h5>
        <a href='https://www.oslofoto.as/vilkaar'>Kjøpsvilkår</a>
        <a href='https://www.oslofoto.as/personvern'>Personvernserklæring</a>
        <a href='https://www.oslofoto.as/cookies'>Cookies</a>
      </div>
      <div className='footer-start'>
        <h5>Oslo Foto AS</h5>
        <p>Orgnr.: 992 841 257</p>
      </div>
    </div>
  )
}

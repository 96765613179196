import React, { useState, useEffect, useRef } from 'react'
import Chevron from '../icons/Chevron.svg'
import { LANGUAGES } from '../resources.js'
import { useTranslation } from 'react-i18next'

const DropDown = () => {
  const dropdown = useRef()
  const { i18n } = useTranslation()
  const [show, setShow] = useState(false)
  const [height, setHeight] = useState(false)

  const [lang, setLang] = useState({ lang: 'English', locale: 'en' })

  // Handles onClick and onKeypress for dropdown values
  const setValue = (event, value) => {
    if (event.key === 'Enter') {
      setLang(value)
      i18n.changeLanguage(value)
    }
    setLang(value)
    i18n.changeLanguage(value)
  }

  const toggleDropdown = () => {
    show ? setHeight('0px') : setHeight('580px')
    setShow(!show)
  }

  // Handles outside clicks for dropdown.
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        show ? setHeight('0px') : setHeight('580px')
        setShow(!show)
      }
    }
    show
      ? document.addEventListener('mousedown', handleClickOutside)
      : document.removeEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [show])

  useEffect(() => {
    const current = LANGUAGES.find((lang) => lang.locale === i18n.language)
    setLang(current === undefined ? { lang: 'English', locale: 'en' } : current)
  }, [i18n.language])

  return (
    <button ref={dropdown} className='dropdown' onClick={toggleDropdown}>
      <p className='selected-language'>{lang.lang}</p>
      <img
        src={Chevron}
        alt='chevron-down'
        style={{ transform: show ? 'rotate(180deg)' : 'rotate(0deg)' }}
      ></img>
      <div
        className='dropdown-content'
        style={{ height: height, opacity: show ? 1 : 0 }}
      >
        {LANGUAGES.map(({ lang, locale }, index) => (
          <p
            tabIndex={show ? '0' : null}
            key={index}
            onClick={(event) => setValue(event, locale)}
            onKeyPress={(event) => setValue(event, locale)}
          >
            {lang}
          </p>
        ))}
      </div>
    </button>
  )
}

export default DropDown

import React from 'react'

const Menu = () => (
  <div className='web-menu'>
    <a
      href='https://www.bildebestilling.no/sections/index.php?'
      className='menu-item'
    >
      Bildebestilling
    </a>
    <a href='https://www.oslofoto.as/film' className='menu-item'>
      Fremkalle film
    </a>
    <div className='menu-item dropdown-container'>
      <a href='https://www.oslofoto.as/produkter'>Produkter</a>
      <div className='web-menu-dropdown'>
        <a href='https://www.oslofoto.as/produkter/film'>Film</a>
        <a href='https://www.oslofoto.as/produkter/morkerom'>mørkerom</a>
        <a href='https://www.oslofoto.as/produkter/fotoalbum'>Fotoalbum</a>
        <a href='https://www.oslofoto.as/produkter/analog'>analoge kameraer</a>
        <a href='https://www.oslofoto.as/produkter/tilbehor'>Kameratilbehør</a>
        <a href='https://www.oslofoto.as/produkter/rammer'>rammer</a>
        <a href='https://www.oslofoto.as/produkter/lysbilder'>
          lysbildefremviser
        </a>
      </div>
    </div>
    <div className='menu-item dropdown-container'>
      <a href='https://www.oslofoto.as/print' className='menu-item'>
        Print til veggen
      </a>
      <div className='web-menu-dropdown'>
        <a href='https://www.oslofoto.as/print/art'>print til veggen</a>
        <a href='https://www.oslofoto.as/print/aluminium'>
          fine art print på aluminium
        </a>
        <a href='https://www.oslofoto.as/print/canvas'>lerret på blindramme</a>
        <a href='https://www.oslofoto.as/print/kapa'>fine art på kapa</a>
      </div>
    </div>
    <a href='https://www.oslofoto.as/digitalisering' className='menu-item'>
      Digiatlisering og restaurering
    </a>
    <a href='https://oslopassfoto.no/' className='menu-item'>
      Passfoto
    </a>
  </div>
)

export default Menu

import React, { useState } from 'react'
import Chevron from '../icons/Chevron.svg'

const DropdownItem = ({ item }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    item.children ? setOpen(!open) : (window.location.href = item.link)
  }
  const handleChildClick = (link) => {
    console.log(link)
    window.location.href = link
  }

  return (
    <>
      <div className='nav-item' onClick={handleClick}>
        <p>{item.text}</p>
        {item.children && <img src={Chevron} alt='Chevron'></img>}
      </div>
      <div className='nav-children'>
        {item.children &&
          open &&
          item.children.map(({ text, link }) => (
            <p
              className='nav-item'
              onClick={() => handleChildClick(link)}
              key={text}
            >
              {text}
            </p>
          ))}
      </div>
    </>
  )
}

export default DropdownItem

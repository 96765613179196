import React , {Suspense}from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
import Header from '../src/components/Header'
import Loading from '../src/components/Loading'
import reportWebVitals from './reportWebVitals';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Header />
    <Suspense fallback={<Loading/>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

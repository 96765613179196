import React from 'react'
import CloseMenu from '../icons/CloseMobile.svg'
import DropdownItem from './DropdownItem'
import { MOBILE_MENU_ITEMS } from '../resources'

const MobileMenu = ({ open, handler }) => (
  <>
    <div className={`mobile-menu ${open ? 'active' : ''}`}>
      <div className='mobile-content'>
        <img
          onClick={handler}
          src={CloseMenu}
          alt=''
          style={{ width: '25px' }}
        />
        <div>
          {MOBILE_MENU_ITEMS.map((item) => (
            <DropdownItem item={item} key={item.text} />
          ))}
        </div>
      </div>
    </div>
    <span
      className='overlay'
      onClick={handler}
      style={{ width: `${open ? '100vw' : '0'}` }}
    ></span>
  </>
)

export default MobileMenu

import React, { useState } from 'react'
import OsloFoto from '../icons/OsloFoto.svg'
import OsloFotoMobile from '../icons/OsloFoto_Mobile.svg'
import MenuBurger from '../icons/MenuBurger.svg'
import Menu from './Menu.jsx'
import MobileMenu from './MobileMenu'

const Header = () => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  const toggleHandler = (bool) => {
    setOpenMobileMenu(!openMobileMenu)
  }

  return (
    <>
      <header className='web-header'>
        <div className='header-links'>
          <div className='header-link-container'>
            <a href={`/`}>
              <p>Åpningstider 9-18 (10-16)</p>
            </a>
            <a href='/'>
              <p>Kontakt oss</p>
            </a>
          </div>
        </div>
        <a href={'/'}>
          <img src={OsloFoto} alt='Oslo Foto' />
        </a>
      </header>
      <header className='mobile-header'>
        <img
          className='MenuBurger'
          src={MenuBurger}
          alt='Åpne mobil meny'
          onClick={() => setOpenMobileMenu(!openMobileMenu)}
        />
        <a href={'/'}>
          <img className='mobile-logo' src={OsloFotoMobile} alt='Oslo Foto' />
        </a>
      </header>
      <MobileMenu open={openMobileMenu} handler={toggleHandler} />
      <Menu />
    </>
  )
}

export default Header

export const LANGUAGES = [
  {
    lang: 'Norsk',
    locale: 'no',
  },
  {
    lang: 'English',
    locale: 'en',
  },
  {
    lang: 'Español',
    locale: 'es',
  },
  {
    lang: 'عربي',
    locale: 'ar',
  },
  {
    lang: 'Français',
    locale: 'fr',
  },
  {
    lang: 'Polskie',
    locale: 'pl',
  },
  {
    lang: '中国人',
    locale: 'zh',
  },
  {
    lang: 'भारतीय',
    locale: 'bn_IN',
  },
  {
    lang: 'Deutsch',
    locale: 'de',
  },
]

export const PRICES = [
  {
    option: 'option1',
    subtext: '3,5x4,5 cm og 5x5 cm',
    price: '249,-',
  },
  {
    option: 'option2',
    subtext: '',
    price: '349,-',
  },
  {
    option: 'option3',
    subtext: '',
    price: '79,-',
  },
  {
    option: 'option4',
    subtext: '',
    price: '79,-',
  },
  {
    option: 'option5',
    subtext: '',
    price: '349,-',
  },
  {
    option: 'option6',
    subtext: '',
    price: '395,-',
  },
  {
    option: 'option8',
    subtext: '',
    price: '495,-',
  },
]
export const OPENING_HOURS = [
  {
    day: 'monday',
    hours: '09:00 - 18:00',
  },
  {
    day: 'tuesday',
    hours: '09:00 - 18:00',
  },
  {
    day: 'wednesday',
    hours: '09:00 - 18:00',
  },
  {
    day: 'thursday',
    hours: '09:00 - 18:00',
  },
  {
    day: 'friday',
    hours: '09:00 - 18:00',
  },
  {
    day: 'saturday',
    hours: '10:00 - 16:00',
  },
  {
    day: 'sunday',
    hours: '09:00 - 18:00',
  },
]
export const MOBILE_MENU_ITEMS = [
  {
    text: 'Bildebestilling',
    link: 'https://www.bildebestilling.no/sections/index.php?',
  },
  {
    text: 'Fremkalle film',
    link: 'https://www.oslofoto.as/film',
  },
  {
    text: 'Produkter',
    children: [
      {
        text: 'Film',
        link: 'https://www.oslofoto.as/produkter/film',
      },
      {
        text: 'Mørkerom',
        link: 'https://www.oslofoto.as/produkter/morkerom',
      },
      {
        text: 'Fotoalbum',
        link: 'https://www.oslofoto.as/produkter/fotoalbum',
      },
      {
        text: 'Analoge Kamerea',
        link: 'https://www.oslofoto.as/produkter/analog',
      },
      {
        text: 'Kamereatilbehør',
        link: 'https://www.oslofoto.as/produkter/tilbehor',
      },
      {
        text: 'Rammer',
        link: 'https://www.oslofoto.as/produkter/rammer',
      },
      {
        text: 'Lysbildefremviser',
        link: 'https://www.oslofoto.as/produkter/lysbilder',
      },
    ],
  },
  {
    text: 'Print til veggen',
    children: [
      {
        text: 'Fine art print',
        link: 'https://www.oslofoto.as/print/art',
      },
      {
        text: 'Fine art print på aluminium',
        link: 'https://www.oslofoto.as/print/aluminium',
      },
      {
        text: 'Lerret på blindramme',
        link: 'https://www.oslofoto.as/print/canvas',
      },
      {
        text: 'fine art print på kapa',
        link: 'https://www.oslofoto.as/print/kapa',
      },
    ],
  },
  {
    text: 'Digiatlisering og restaurering',
    link: 'https://www.oslofoto.as/digitalisering',
  },

  {
    text: 'Kamerareperasjon',
    link: 'https://www.oslofoto.as/passfoto',
  },
]

import React from 'react'

const Loading = () => {
  return (
    <img
      src={`../passfoto.jpg`}
      alt='Oslo Passfoto'
      className='header-img item1'
    />
  )
}

export default Loading

import DropDown from './components/DropDown.jsx'
import { useTranslation } from 'react-i18next'
import './index.css'
import { PRICES, OPENING_HOURS } from './resources.js'
import { Footer } from './components/Footer.jsx'

function App() {
  const { t, i18n } = useTranslation()

  return (
    <>
      <main>
        <section className='header-section'>
          <img
            src={`../passfoto.jpg`}
            alt='Oslo Passfoto'
            className='header-img item1'
          />
          <div className='header-title'>
            <h1>{t('content.header.title')}</h1>
            <DropDown />
          </div>
        </section>

        <section className='page'>
          <div className='grid'>
            <div className='container'>
              <p>{t('content.header.text')}</p>
            </div>

            <div className='container'>
              <h5>{t('content.examples.title')} </h5>
              <ul>
                <li>{t('content.examples.passport')}</li>
                <li>{t('content.examples.visa')}</li>
                <li>{t('content.examples.bank')}</li>
                <li>{t('content.examples.id')}</li>
                <li>{t('content.examples.cv')}</li>
                <li>{t('content.examples.driversLicense')}</li>
                <li>{t('content.examples.staffPhotos')}</li>
              </ul>
              {i18n.language === 'no' && (
                <>
                  <h2>{t('content.guidelines.title')}</h2>
                  <p>{t('content.guidelines.text')}</p>
                </>
              )}

              <div>
                <h2>{t('content.location.title')}</h2>
                <p>{t('content.location.address.name')}</p>
                <a href='https://goo.gl/maps/U2xEruiWqPxew3wN9'>
                  {t('content.location.address.street')}
                </a>
                <p>
                  {t('content.location.address.postalCode')}{' '}
                  {t('content.location.address.city')}
                </p>
                <p style={{ marginTop: '20px' }}>
                  {t('content.location.info')}
                </p>
                <div className='photo-container'>
                  <img
                    className='store-photo'
                    src={`../OsloFoto.jpg`}
                    alt='Oslo Foto lokaler'
                  />
                </div>
              </div>

              <div>
                <h2>{t('content.openingHours.title')}</h2>
                <table>
                  <tbody>
                    {OPENING_HOURS.map((day) => (
                      <tr key={day.day}>
                        <td style={{ paddingRight: '30px' }}>
                          {t(`content.openingHours.days.${day.day}`)}
                        </td>
                        <td>
                          {day.day === 'sunday'
                            ? t('content.openingHours.closed')
                            : day.hours}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div>
                <h2>{t('content.contact.title')}</h2>
                <p>{t('content.contact.tlf')}</p>
                <p>{t('content.contact.email')}</p>
              </div>
            </div>

            <div className='container pricetable'>
              <div className='pricetable-header-container'>
                <p>{t('pricetable.prices')}</p>
                <h5>{t('pricetable.passPhoto')}</h5>
              </div>
              {PRICES.map((item) => (
                <div className='pricelist-item' key={item.option}>
                  <div>
                    <p>{t(`pricetable.${item.option}.text`)}</p>
                    <p className='pricetable-subtext'>
                      {t(`pricetable.${item.option}.subtext`)}
                    </p>
                  </div>
                  <h4 style={{ minWidth: 'fit-content', margin: '24px 0' }}>
                    <span className='nok'>
                      {i18n.language !== 'no' && `NOK`}
                    </span>{' '}
                    {item.price}
                  </h4>
                </div>
              ))}
              <div className='delivery-container'>
                <h4 className='delivery-time'>
                  {t('pricetable.deliveryTime')}
                </h4>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default App
